import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import FieldTechnicalArticle from "../components/field-technical-article";
import { IFieldTechnicalArticle } from "../components/field-technical-article/field-technical-article";
import Footer from "../components/footer";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import {
  // articleSchema,
  breadCrumbSchema
} from "../components/html-schema-snippets";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import PageTopArticleHeading from "../components/page-top-article-heading";

interface IProps {
  nodeBasicPage: IBasicArticleData;
}

interface IBasicArticleData {
  title: string;
  created: string;
  changed: string;
  path: {
    alias: string;
  };
  field_meta_description: string;
  relationships: {
    field_technical_article: IFieldTechnicalArticle[];
  };
}

const BasicArticleWrapper = styled.div`
  padding-bottom: 16%;

  @media (min-width: 568px) {
    padding-bottom: 8.8rem;
  }
`;

const BasicArticle = ({ data }: { data: IProps }) => {
  const article = data.nodeBasicPage;

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: article.title,
      path: article.path.alias
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];
  // let parseRelatedArticles: Array<IArticle> | null = null;

  // // Parse the related articles to get the data structure into the Typed state
  // if (relatedArticles) {
  //   parseRelatedArticles = relatedArticles.map(article => {
  //     return { node: article };
  //   });
  // }

  return (
    <Layout>
      <>
        <HelmetWrapper
          title={article.title}
          description={article.field_meta_description}
          ogType="article"
          updatedAt={article.changed}
          canonicalPath={article.path.alias}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header
          role="banner"
          id="hero"
        >
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTop gradient="orangeYellow">
            <PageTopArticleHeading
              className="top-content"
              type="basic"
              title={article.title}
            />
          </PageTop>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {article.title}
          </h1>

          <BasicArticleWrapper>
            <FieldTechnicalArticle
              type="basic"
              paragraphs={article.relationships.field_technical_article}
            />
          </BasicArticleWrapper>
        </main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default BasicArticle;

export const query = graphql`
  query($slug: String!) {
    nodeBasicPage(path: { alias: { eq: $slug } }) {
      ...getBasicPageData
    }
  }
`;
