import moment from "moment";
import * as React from "react";

import { IFieldProjectTag } from "../../pages/projects";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { HalfColumnWidthWrapper } from "../confines-wrappers";
import { IFieldTechnicalArticleColumnType } from "../field-technical-article/field-technical-article";

interface IProps {
  className?: string;
  type: IFieldTechnicalArticleColumnType;
  title: string;
  authoredOn?: string;
  authoredBy?: string;
  tags?: IFieldProjectTag[];
}

interface IAuthoredOn {
  authoredOn: string | undefined;
}

interface IAuthoredBy {
  authoredBy: string | undefined;
}

interface ITags {
  tags: IFieldProjectTag[] | undefined;
}

interface IPageTopArticleHeadingContainer {
  type: IFieldTechnicalArticleColumnType;
  hasAuthoredOn: boolean;
}

const PageTopArticleHeadingContainer = styled<
  IPageTopArticleHeadingContainer,
  "div"
>("div")`
  padding-top: 10%;
  padding-bottom: 10%;

  .content-wrapper {
    position: relative;

    .heading,
    .tags,
    .authored-on,
    .authored-by {
      transition: 0.2s ease-in-out;
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
    }

    .authored-on,
    .authored-by,
    .tags {
      display: block;
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
    }

    .heading {
      margin: 0;
      padding-top: ${props =>
    props.hasAuthoredOn && props.type === "basic" ? "2rem" : "0"};
      font-size: ${props =>
    props.type === "basic" ?
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "subhead_1"
      }) :
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "subhead_2"
      })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
    }

    .authored-on {
      position: absolute;
      top: 0;
      left: 2rem;
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_1"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "normalized"
    })};
      letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
      text-transform: uppercase;
    }

    .authored-by {
      padding-top: 1.8rem;
    }

    .tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 0;
      padding-bottom: 0;
      padding-top: 6%;
      list-style: none;

      li {
        margin-bottom: 0;
        padding-right: 0.2em;
        padding-top: 0.4rem;

        &:after {
          content: ", ";
        }

        &:last-child {
          padding-right: 0;

          &:after {
            content: "";
          }
        }
      }
    }
  }

  @media (min-width: 375px) {
    .content-wrapper {
      .heading {
        padding-top: ${props =>
    props.hasAuthoredOn && props.type === "basic" ? "2.4rem" : "0"};
        font-size: ${props =>
    props.type === "basic" ?
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "subhead_2"
      }) :
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "subhead_3"
      })};
      }

      .authored-by {
        padding-top: 2rem;
      }
    }
  }

  @media (min-width: 480px) {
    .content-wrapper {
      .heading {
        font-size: ${props =>
    props.type === "basic" ?
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "title"
      }) :
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "display_2"
      })};
      }

      .authored-on {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
      }

      .authored-by,
      .tags {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
      }

      .authored-by {
        padding-top: 2.2em;
      }

      .tags {
        padding-top: 2.8rem;
      }
    }
  }

  @media (min-width: 600px) {
    .content-wrapper {
      .heading {
        padding-top: ${props =>
    props.hasAuthoredOn && props.type === "basic" ? "3rem" : "0"};
        font-size: ${props =>
    props.type === "basic" ?
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "subhead_3"
      }) :
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "display_3"
      })};
      }

      .authored-by {
        padding-top: 2.2em;
      }
    }
  }

  @media (min-width: 768px) {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;

    .content-wrapper {
      .heading {
        font-size: ${props =>
    props.type === "basic" ?
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "display_1"
      }) :
      ({ theme }) => fontSizeHelper({
        theme,
        variant: "display_4"
      })};
      }

      .authored-on {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
      }

      .authored-by,
      .tags {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
      }
    }
  }
`;

const AuthoredOn = ({ authoredOn }: IAuthoredOn) => {
  let date = "";

  if (!authoredOn) {
    return null;
  }

  // Set the date
  date = moment.utc(authoredOn).format("D MMM YYYY");

  return (
    <span className="authored-on">
      <span className="off-screen">
        Published on
        {" "}
      </span>

      <time dateTime={authoredOn}>
        {date}
      </time>
    </span>
  );
};

const AuthoredBy = ({ authoredBy }: IAuthoredBy) => {
  if (!authoredBy) {
    return null;
  }

  return (
    <span className="authored-by">
      {`By ${authoredBy}`}
    </span>
  );
};

const Tags = ({ tags }: ITags) => {
  if (!tags) {
    return null;
  }

  return (
    <ul className="tags">
      {tags &&
        tags.map((tag: IFieldProjectTag, i: number) => {
          return (
            <li key={i}>
              {tag.name}
            </li>
          );
        })}
    </ul>
  );
};

const PageTopArticleHeading = ({
  className,
  type,
  title,
  authoredOn,
  authoredBy,
  tags
}: IProps) => (
  <PageTopArticleHeadingContainer
    type={type}
    className={className}
    hasAuthoredOn={authoredOn ? true : false}
  >
    <HalfColumnWidthWrapper className="content-wrapper">
      <p className="heading">
        {title}
      </p>

      <AuthoredOn authoredOn={authoredOn} />

      <AuthoredBy authoredBy={authoredBy} />

      <Tags tags={tags} />
    </HalfColumnWidthWrapper>
  </PageTopArticleHeadingContainer>
);

export default PageTopArticleHeading;
